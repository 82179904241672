import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Turnstile } from '@marsidev/react-turnstile';

export default function AddPriceModal({ isOpen, onClose, onPriceSubmit, racketUuid }) {
  const { t } = useTranslation();
  const [price, setPrice] = useState('');
  const [currency, setCurrency] = useState('EUR');
  const [link, setLink] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');

  const handleSubmit = async () => {
    if (!turnstileToken) {
      alert(t('Please complete the CAPTCHA.'));
      return;
    }

    try {
      const response = await fetch('https://rackets.top/api/rackets/add-price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          racketUuid,
          price: parseFloat(price),
          currency,
          link,
          turnstileToken,
        }),
      });

      if (response.ok) {
        onPriceSubmit();
        setTurnstileToken("");
        onClose();
      } else {
        alert(t('There was an error submitting your price. Please try again later.'));
      }
    } catch (error) {
      console.error('Error submitting price:', error);
      alert(t('There was an error submitting your price. Please try again later.'));
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {t('Add a Price')}
                    </Dialog.Title>
                    <p className="text-sm text-gray-500">
                    {t('Found a price we missed? Please help us keep our data accurate by adding a price below. Your contribution helps other users find the best deals on rackets.')}
                    </p>
                    <div className="mt-2">
                      <input
                        type="number"
                        step="0.01"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder={t('Enter price')}
                        value={price}
                        onChange={(e) => setPrice(e.target.value)}
                      />
                      <select
                        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                      >
                        <option value="EUR">EUR</option>
                        <option value="USD">USD</option>
                        <option value="GBP">GBP</option>
                        {/* Ajoutez d'autres devises ici */}
                      </select>
                      <input
                        type="text"
                        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder={t('Enter link to the price')}
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />
                    </div>
                    <div className="mt-4">
                      <Turnstile
                        siteKey="0x4AAAAAAAijnMxKWbkLm5SU"
                        onSuccess={(token) => setTurnstileToken(token)}
                      />
                    </div>
                    <div className="mt-4 flex justify-end">
                      <button
                        type="button"
                        className="mr-2 inline-flex justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={onClose}
                      >
                        {t('Cancel')}
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={handleSubmit}
                      >
                        {t('Submit')}
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
