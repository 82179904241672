import React, { useState, useEffect } from 'react';
import { useBreadcrumb } from '../BreadcrumbContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IntlProvider, FormattedNumber } from 'react-intl';
import { FaCheckCircle, FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

const questions = [
  {
    "id": "price",
    "name": "What is your budget?",
    "options": [
      { id: "0-50", "name": "0 € - 50 €", description: "I prefer an affordable racket within 0 € to 50 €." },
      { id: "50-100", "name": "50 € - 100 €", description: "I want a budget-friendly racket within 50 € to 100 €." },
      { id: "100-150", "name": "100 € - 150 €", description: "I'm looking for a mid-range racket within 100 € to 150 €." },
      { id: "150+", "name": "150 €+", description: "I'm willing to invest more than 150 € for a high-quality racket." },
      { id: 'no-preference', name: 'No Preference', description: '' }
    ]
  },
  {
    id: 'balance',
    name: 'What balance do you prefer for your racket?',
    options: [
      { id: 'HEAD_HEAVY', name: 'Head Heavy', description: 'I prefer a head-heavy racket to generate more power in my smashes.' },
      { id: 'BALANCED', name: 'Even Balance', description: 'I want a well-balanced racket that offers both power and maneuverability.' },
      { id: 'HEAD_LIGHT', name: 'Head Light', description: 'I prefer a head-light racket for better maneuverability and quick reactions at the net.' },
      { id: 'no-preference', name: 'No Preference', description: '' }
    ],
  },
  {
    id: 'shaft-stiffness',
    name: 'What shaft stiffness do you prefer?',
    options: [
      { id: 'STIFF', name: 'Stiff', description: 'I prefer a stiff shaft for maximum control, perfect for experienced players.' },
      { id: 'MEDIUM', name: 'Medium', description: 'I am looking for a shaft with medium flexibility, offering a good balance of power and control.' },
      { id: 'FLEXIBLE', name: 'Flexible', description: 'I prefer a flexible shaft to generate more power with less effort, ideal for beginners and intermediates.' },
      { id: 'no-preference', name: 'No Preference', description: '' }
    ],
  },
  {
    id: 'racket-weight',
    name: 'What racket weight do you prefer?',
    options: [
      { id: '2U', name: '2U (90-94g)', description: 'I prefer a heavier racket for more stability and power in my shots.' },
      { id: '3U', name: '3U (85-89g)', description: 'I want a good balance of power and maneuverability with a medium-weight racket.' },
      { id: '4U', name: '4U (80-84g)', description: 'I choose a lighter racket for quick maneuverability and increased responsiveness.' },
      { id: '5U', name: '5U (75-79g)', description: 'I prefer a very light racket to maximize my speed and reflexes.' },
      { id: 'no-preference', name: 'No Preference', description: '' }
    ],
  },
  {
    id: 'grip-size',
    name: 'What grip size do you prefer?',
    options: [
      { id: 'G4', name: 'G4 (Medium)', description: 'I choose a medium grip size for a good balance of comfort and control.' },
      { id: 'G5', name: 'G5 (Small)', description: 'I prefer a smaller grip for increased maneuverability and better shot control.' },
      { id: 'G6', name: 'G6 (Very Small)', description: 'I want a very small grip for a thin and precise hold, ideal for smaller hands.' },
      { id: 'no-preference', name: 'No Preference', description: '' }
    ],
  },
  {
    id: 'string-tension',
    name: 'What maximum string tension do you prefer?',
    options: [
      { id: 'low', name: 'Low Tension Capacity (6.4 - 9.4 kg)', description: 'Rackets that can handle lower tensions, suitable for recreational or beginner players.' },
      { id: 'medium-low', name: 'Medium-Low Tension Capacity (9.5 - 10.9 kg)', description: 'Rackets that can handle moderate tensions, providing a balance between durability and performance.' },
      { id: 'medium', name: 'Medium Tension Capacity (11.0 - 12.9 kg)', description: 'Rackets capable of supporting higher tensions, ideal for more competitive players.' },
      { id: 'medium-high', name: 'Medium-High Tension Capacity (13.0 - 14.9 kg)', description: 'High-performance rackets that can withstand high tensions, offering greater control and precision.' },
      { id: 'high', name: 'High Tension Capacity (15.0 - 17.0 kg)', description: 'Rackets designed for advanced players, capable of supporting the highest tensions for maximum control.' },
      { id: 'no-preference', name: 'No Preference', description: '' }
    ],
  },
  {
    id: 'racket-brand',
    name: 'What racket brand do you prefer?',
    options: [
      { id: 'Yonex', name: 'Yonex', description: '' },
      { id: 'Li-Ning', name: 'Li-Ning', description: '' },
      { id: 'Dunlop', name: 'Dunlop', description: '' },
      { id: 'Carlton', name: 'Carlton', description: '' },
      { id: 'Babolat', name: 'Babolat', description: '' },
      { id: 'FZ Forza', name: 'FZ Forza', description: '' },
      { id: 'Kawasaki', name: 'Kawasaki', description: '' },
      { id: 'Badmania', name: 'Badmania', description: '' },
      { id: 'no-preference', name: 'No Preference', description: '' }
    ],
  },
];

const tensionRanges = {
  low: { min: 6.4, max: 9.4 },
  'medium-low': { min: 9.5, max: 10.9 },
  medium: { min: 11.0, max: 12.9 },
  'medium-high': { min: 13, max: 14.9 },
  high: { min: 15.0, max: 17 }
};

const priceRanges = {
  'no-preference': { min: 0, max: Infinity },
  '0-50': { min: 0, max: 50 },
  '50-100': { min: 50, max: 100 },
  '100-150': { min: 100, max: 150 },
  '150+': { min: 150, max: Infinity }
};

const RacketFinder = ({ rackets }) => {
  const { setBreadcrumbs } = useBreadcrumb();
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState({});
  const [scoredRackets, setScoredRackets] = useState([]);
  const [visibleRackets, setVisibleRackets] = useState([]);
  const [racketsToShow, setRacketsToShow] = useState(8);

  useEffect(() => {
    setBreadcrumbs([
      { name: t('Home'), href: '/' },
      { name: t('Badminton'), href: '/badminton/rackets' },
      { name: t('Find my racket'), href: '/badminton/racket-finder' },
    ]);
  }, [setBreadcrumbs, t]);

  useEffect(() => {
    const savedFilters = localStorage.getItem('racketFilters');
    if (savedFilters) {
      setSelectedOptions(JSON.parse(savedFilters));
    }
  }, []);

  const isOptionMatch = (racketValue, selectedValue) => selectedValue && racketValue === selectedValue ? 1 : 0;

  const isArrayMatch = (racketValues, selectedValue) => selectedValue && racketValues.includes(selectedValue) ? 1 : 0;

  const isTensionCategoryMatch = (racketTensions, selectedCategory) => {
    if (!selectedCategory) return 0;
    const range = tensionRanges[selectedCategory];
    return racketTensions.some(tension => tension >= range.min && tension <= range.max) ? 1 : 0;
  };

  const isPriceMatch = (lowestPrice, selectedPriceRange) => {
    if (!selectedPriceRange || !lowestPrice) return 0;
    const range = priceRanges[selectedPriceRange];
    return lowestPrice >= range.min && lowestPrice < range.max ? 1 : 0;
  };

  const calculateMatchingCriteria = (racket) => {
    let matchedCriteria = 0;

    if (selectedOptions['price'] && selectedOptions['price'] !== 'no-preference') {
      if (!racket.lowestPrice || !isPriceMatch(racket.lowestPrice.amount, selectedOptions['price'])) {
        return -1;
      }
    }

    // Match other criteria
    if (selectedOptions['racket-brand'] && isOptionMatch(racket.brand, selectedOptions['racket-brand'])) matchedCriteria++;
    if (selectedOptions['balance'] && isOptionMatch(racket.balance, selectedOptions['balance'])) matchedCriteria++;
    if (selectedOptions['shaft-stiffness'] && isOptionMatch(racket.flexibility, selectedOptions['shaft-stiffness'])) matchedCriteria++;
    if (selectedOptions['racket-weight'] && isArrayMatch(racket.weights, selectedOptions['racket-weight'])) matchedCriteria++;
    if (selectedOptions['grip-size'] && isArrayMatch(racket.grip_sizes, selectedOptions['grip-size'])) matchedCriteria++;
    if (selectedOptions['string-tension'] && isTensionCategoryMatch(racket.max_tensions, selectedOptions['string-tension'])) matchedCriteria++;

    return matchedCriteria;
  };

  const totalCriteria = () => Object.values(selectedOptions).filter(value => value && value !== 'no-preference').length;

  useEffect(() => {
    const updatedRackets = rackets
      .map(racket => {
        const matchingCriteria = calculateMatchingCriteria(racket);
        const selectedCriteria = totalCriteria();
        const percentageMatch = selectedCriteria > 0 ? (matchingCriteria / selectedCriteria) * 100 : 0;
        return { ...racket, score: percentageMatch };
      })
      .filter(racket => racket.score !== -1) // Exclude rackets outside of the price range
      .sort((a, b) => {
        // Tri basé sur le score de correspondance
        if (b.score !== a.score) return b.score - a.score;

        // Priorité à la marque si elle est sélectionnée
        const brandA = isOptionMatch(a.brand, selectedOptions['racket-brand']);
        const brandB = isOptionMatch(b.brand, selectedOptions['racket-brand']);
        if (brandB !== brandA) return brandB - brandA;

        // Priorité à l'équilibre si sélectionné
        const balanceA = isOptionMatch(a.balance, selectedOptions['balance']);
        const balanceB = isOptionMatch(b.balance, selectedOptions['balance']);
        if (balanceB !== balanceA) return balanceB - balanceA;

        // Priorité à la flexibilité si sélectionnée
        const flexibilityA = isOptionMatch(a.flexibility, selectedOptions['shaft-stiffness']);
        const flexibilityB = isOptionMatch(b.flexibility, selectedOptions['shaft-stiffness']);
        if (flexibilityB !== flexibilityA) return flexibilityB - flexibilityA;

        // Priorité aux raquettes qui ont un prix
        if (!a.lowestPrice) return 1;
        if (!b.lowestPrice) return -1;
        const priceComparison = a.lowestPrice.amount - b.lowestPrice.amount;
        if (priceComparison !== 0) return priceComparison;

        // Priorité aux raquettes avec des liens Amazon
        const aHasAmazon = a.amazon_urls && a.amazon_urls.length > 0;
        const bHasAmazon = b.amazon_urls && b.amazon_urls.length > 0;
        if (aHasAmazon && !bHasAmazon) return -1;
        if (!aHasAmazon && bHasAmazon) return 1;

        return 0;
      });

    setScoredRackets(updatedRackets);
    setVisibleRackets(updatedRackets.slice(0, racketsToShow));
  }, [selectedOptions, rackets, racketsToShow]);

  const handleOptionChange = (questionId, optionId) => {
    setSelectedOptions((prev) => {
      const newOptions = { ...prev, [questionId]: optionId };
      localStorage.setItem('racketFilters', JSON.stringify(newOptions));
      return newOptions;
    });
  };

  const loadMoreRackets = () => setRacketsToShow((prev) => prev + 8);

  const renderStars = (percentage) => {
    const maxStars = 5;
    const stars = (percentage / 100) * maxStars;
    const fullStars = Math.floor(stars);
    const halfStar = stars % 1 >= 0.5 ? 1 : 0;
    const emptyStars = maxStars - fullStars - halfStar;

    return (
      <>
        {[...Array(fullStars)].map((_, i) => <FaStar key={`full-${i}`} className="text-yellow-500" />)}
        {halfStar === 1 && <FaStarHalfAlt className="text-yellow-500" />}
        {[...Array(emptyStars)].map((_, i) => <FaRegStar key={`empty-${i}`} className="text-yellow-500" />)}
      </>
    );
  };

  return (
    <main className="mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8">
      <div className="border-b border-gray-200 pb-10 pt-24">
        <h1 className="text-4xl font-bold tracking-tight text-gray-900">{t('Find my racket')}</h1>
        <p className="mt-4 text-base text-gray-500">
          {t('To help you find the perfect badminton racket, please take a moment to answer a series of questions about your playing style, preferences, and skill level.')}
        </p>
      </div>
      <form className="space-y-8 divide-y divide-gray-200">
        {questions.map((question) => (
          <fieldset key={question.id} aria-label={t(question.name)}>
            <legend className="text-base font-medium text-gray-900">{t(question.name)}</legend>
            <div className="mt-4 space-y-4">
              {question.options.map((option) => (
                <div key={option.id} className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id={option.id}
                      name={question.id}
                      type="radio"
                      aria-describedby={`${option.id}-description`}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={() => handleOptionChange(question.id, option.id)}
                      checked={selectedOptions[question.id] === option.id}
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label htmlFor={option.id} className="font-medium text-gray-900">
                      {t(option.name)}
                    </label>
                    {option.description && (
                      <p id={`${option.id}-description`} className="text-gray-500">
                        {t(option.description)}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </fieldset>
        ))}
      </form>
      <section aria-labelledby="product-heading" className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3">
        <div className="grid grid-cols-1 gap-y-4 pt-6 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4">
          {visibleRackets.map((racket, index) => (
            <div key={`${racket.uuid}-${index}`} className="group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white">
              <div className="aspect-h-4 aspect-w-3 bg-gray-200 sm:aspect-none group-hover:opacity-75 sm:h-96">
                <img src={`${racket.images ? racket.images[0] : '/images/logo.png'}`} className="h-full w-full object-cover object-center sm:h-full sm:w-full" alt={racket.name} />
              </div>
              <div className="flex flex-1 flex-col space-y-2 p-4">
                <h3 className="text-sm font-medium text-gray-900">
                  <Link to={`/badminton/racket/${racket.uuid}`}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {racket.name || t('Name not available')}
                  </Link>
                </h3>
                <hr className="border-t border-gray-200 my-2" />
                <div className="flex flex-1 flex-col justify-end">
                  <div className="flex justify-between">
                    <p className="text-sm italic text-gray-500">
                      {t('Brand')}: {racket.brand || t('/')}
                    </p>
                    {isOptionMatch(racket.brand, selectedOptions['racket-brand']) ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <hr className="border-t border-gray-200 my-2" />
                  <div className="flex justify-between">
                    <p className="text-sm italic text-gray-500">
                      {t('Balance')}: {t(racket.balance) || t('/')}
                    </p>
                    {isOptionMatch(racket.balance, selectedOptions['balance']) ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <hr className="border-t border-gray-200 my-2" />
                  <div className="flex justify-between">
                    <p className="text-sm italic text-gray-500">
                      {t('Flexibility')}: {t(racket.flexibility) || t('/')}
                    </p>
                    {isOptionMatch(racket.flexibility, selectedOptions['shaft-stiffness']) ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <hr className="border-t border-gray-200 my-2" />
                  <div className="flex justify-between">
                    <p className="text-sm italic text-gray-500">
                      {t('Weights')}: {racket.weights ? racket.weights.join(', ') : t('/')}
                    </p>
                    {isArrayMatch(racket.weights, selectedOptions['racket-weight']) ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <hr className="border-t border-gray-200 my-2" />
                  <div className="flex justify-between">
                    <p className="text-sm italic text-gray-500">
                      {t('Grip sizes')}: {racket.grip_sizes.length > 0 ? racket.grip_sizes.join(', ') : t('/')}
                    </p>
                    {isArrayMatch(racket.grip_sizes, selectedOptions['grip-size']) ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <hr className="border-t border-gray-200 my-2" />
                  <div className="flex justify-between">
                    <p className="text-sm italic text-gray-500">
                      {t('Max tensions')}: {racket.max_tensions.length > 0 ? racket.max_tensions.join(', ') : t('/')}
                    </p>
                    {isTensionCategoryMatch(racket.max_tensions, selectedOptions['string-tension']) ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <span></span>
                    )}
                  </div>
                  <hr className="border-t border-gray-200 my-2" />
                  <div className="flex justify-between">
                    <p className="text-sm italic text-gray-500">
                      {t('Price')}: {racket.lowestPrice ? (
                        <IntlProvider locale="fr">
                          <FormattedNumber value={racket.lowestPrice.amount} style="currency" currency={racket.lowestPrice.currency} />
                        </IntlProvider>
                      ) : (
                        t('Price not available')
                      )}
                    </p>
                    {racket.lowestPrice && isPriceMatch(racket.lowestPrice.amount, selectedOptions['price']) ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
                <hr className="border-t border-gray-200 my-2" />
                <div className="flex items-center justify-between">
                  <p className="text-sm italic text-gray-500">{t('Match Score')}:</p>
                  <div className="flex space-x-0">
                    {renderStars(racket.score)} {/* Affiche le score sous forme d'étoiles */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {visibleRackets.length < scoredRackets.length && (
          <div className="mt-6 text-center">
            <button onClick={loadMoreRackets} className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              {t('Load More')}
            </button>
          </div>
        )}
      </section>
    </main>
  );
};

export default RacketFinder;
