import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { useBreadcrumb } from '../BreadcrumbContext';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { IntlProvider, FormattedNumber } from 'react-intl';
import { FaAmazon, FaBalanceScale, FaExclamationTriangle, FaPlus } from 'react-icons/fa';
import ErrorReportModal from './ErrorReportModal';
import AddPriceModal from './AddPriceModal';

export default function RacketDetails({ rackets }) {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setBreadcrumbs } = useBreadcrumb();
  const racket = rackets.find(r => r.uuid === uuid);
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);
  const [showAddPriceModal, setShowAddPriceModal] = useState(false);
  const [amazonLink, setAmazonLink] = useState('');

  useEffect(() => {
    if (racket) {
      setBreadcrumbs([
        { name: t('Home'), href: '/' },
        { name: t('Badminton'), href: '/badminton/rackets' },
        { name: t('Rackets'), href: '/badminton/rackets' },
        { name: racket.name, href: `/badminton/racket/${uuid}` },
      ]);
    }
  }, [racket, setBreadcrumbs, uuid, t]);

  useEffect(() => {
    if (!racket || !racket.amazon_urls || racket.amazon_urls.length === 0) return;
    const currentLanguage = i18n.language;
    let amazonBaseUrl = 'https://www.amazon.com';

    if (currentLanguage.startsWith('fr')) {
      amazonBaseUrl = 'https://www.amazon.fr';
    } else if (currentLanguage.startsWith('de')) {
      amazonBaseUrl = 'https://www.amazon.de';
    } else if (currentLanguage.startsWith('es')) {
      amazonBaseUrl = 'https://www.amazon.es';
    } else if (currentLanguage.startsWith('it')) {
      amazonBaseUrl = 'https://www.amazon.it';
    } else if (currentLanguage.startsWith('jp')) {
      amazonBaseUrl = 'https://www.amazon.co.jp';
    } else if (currentLanguage.startsWith('gb') || currentLanguage.startsWith('en-GB')) {
      amazonBaseUrl = 'https://www.amazon.co.uk';
    }

    const amazonUrl = racket.amazon_urls.find(url => url.includes(amazonBaseUrl)) || racket.amazon_urls[0];

    const affiliateTag = 'racketstop-21';
    const urlWithAffiliate = amazonUrl.includes('?') 
      ? `${amazonUrl}&tag=${affiliateTag}` 
      : `${amazonUrl}?tag=${affiliateTag}`;

    setAmazonLink(urlWithAffiliate);

  }, [racket, i18n.language]);

  if (!racket) {
    return <div>{t('Racket not found')}</div>;
  }

  const addToComparator = () => {
    const storedComparator = JSON.parse(localStorage.getItem('racketComparator')) || [];
    if (!storedComparator.includes(racket.uuid)) {
      storedComparator.push(racket.uuid);
      localStorage.setItem('racketComparator', JSON.stringify(storedComparator));
    }
    setShowNotification(true);
  };

  const goToComparator = () => {
    navigate('/badminton/racket-comparator');
  };

  racket.relatedRackets = rackets.filter(tmpRacket => {
    if (racket.uuid === tmpRacket.uuid) return false;

    let similarityScore = 0;

    const intersection = racket.materials.filter(item => tmpRacket.materials.includes(item));

    if (intersection.length === 3) similarityScore++;

    if (racket.weights.some(item => tmpRacket.weights.includes(item))) {
      similarityScore++;
    }

    if (tmpRacket.balance === racket.balance) similarityScore++;
    if (tmpRacket.flexibility === racket.flexibility) similarityScore++;
    if (tmpRacket.player_type === racket.player_type) similarityScore++;
    if (tmpRacket.player_level === racket.player_level) similarityScore++;

    return similarityScore >= 6;
  });

  const handleReportError = () => {
    setShowErrorModal(true);
  };

  const handleAddPriceClick = () => {
    setShowAddPriceModal(true);
  };

  const handlePriceSubmit = () => {
    setShowErrorNotification(true);
    setShowAddPriceModal(false);
  };

  return (
    <IntlProvider locale="fr">
      <main className="mx-auto max-w-7xl sm:px-6 sm:pt-16 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-none">
          <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
            <Tab.Group as="div" className="flex flex-col-reverse">
              <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                <Tab.List className="grid grid-cols-4 gap-6">
                  {racket.images.map((image, index) => (
                    <Tab
                      key={index}
                      className="group relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                    >
                      <span className="sr-only">{`${racket.name} image ${index + 1}`}</span>
                      <span className="absolute inset-0 overflow-hidden rounded-md">
                        <img alt="" src={image} className="h-full w-full object-cover object-center" />
                      </span>
                      <span
                        aria-hidden="true"
                        className="pointer-events-none absolute inset-0 rounded-md ring-2 ring-transparent ring-offset-2 group-data-[selected]:ring-indigo-500"
                      />
                    </Tab>
                  ))}
                </Tab.List>
              </div>

              <Tab.Panels className="aspect-h-1 aspect-w-1 object-contain w-full">
                {racket.images.map((image, index) => (
                  <Tab.Panel key={index}>
                    <img
                      alt={`${racket.name} image ${index + 1}`}
                      src={image}
                      className="h-full w-full object-contain object-center sm:rounded-lg"
                    />
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>

            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">{racket.brand} - {racket.name}</h1>

              <div className="mt-3">
                <h2 className="sr-only">{t('Racket information')}</h2>
                {racket.lowestPrice ? (
                  <p className="text-3xl tracking-tight text-gray-900">
                    <FormattedNumber value={racket.lowestPrice.amount} style="currency" currency={racket.lowestPrice.currency} />
                  </p>
                ) : (
                  <p className="text-3xl tracking-tight text-gray-900">
                    {t('Price not available')}
                  </p>
                )}
              </div>

              <section aria-labelledby="details-heading" className="mt-12">
                <div className="mt-6 border-t border-gray-100">
                  <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Player type')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{t(`${racket.player_type}_SHORT`)}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Player level')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{t(racket.player_level)}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Balance')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{t(racket.balance)}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Flexibility')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{t(racket.flexibility)}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Weights')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{racket.weights.join(', ') || t('Information not available')}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Max tensions')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{racket.max_tensions.join(', ') || t('Information not available')}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Grip sizes')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{racket.grip_sizes.join(', ') || t('Information not available')}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Materials')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{racket.materials.join(', ') || t('Information not available')}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Technologies')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{racket.technologies.join(', ') || t('Information not available')}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dt className="text-sm font-medium leading-6 text-gray-900">{t('Official website')}</dt>
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <a href={racket.url} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-900">{racket.url}</a>
                      </dd>
                    </div>
                    {amazonLink ? (
                      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                          <button
                            onClick={() => window.open(amazonLink, '_blank')}
                            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white transition-transform duration-300 ease-in-out bg-gradient-to-r from-yellow-400 via-yellow-500 to-yellow-600 rounded-md shadow-lg hover:scale-105 hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                          >
                            {/* Icône Amazon */}
                            <FaAmazon className="w-5 h-5 mr-2 text-white" />
                            {t('Buy on Amazon')}
                          </button>
                        </dd>
                      </div>
                    ) : null}
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                        <button
                          type="button"
                          className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white transition-transform duration-300 ease-in-out bg-gradient-to-r from-green-500 via-green-600 to-green-700 rounded-md shadow-lg hover:scale-105 hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                          onClick={addToComparator}
                        >
                          <FaBalanceScale className="w-5 h-5 mr-2 text-white" /> {/* Icône FaBalanceScale */}
                          {t('Add to Comparator')}
                        </button>
                      </dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                      <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white transition-transform duration-300 ease-in-out bg-gradient-to-r from-red-500 via-red-600 to-red-700 rounded-md shadow-lg hover:scale-105 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                        onClick={handleReportError}
                      >
                        <FaExclamationTriangle className="w-5 h-5 mr-2 text-white" /> {/* Icône FaExclamationTriangle */}
                        {t('Report an Error')}
                      </button>
                      </dd>
                    </div>
                  </dl>
                </div>
              </section>
            </div>
          </div>

          <section aria-labelledby="related-heading" className="mt-10 border-t border-gray-200 px-4 py-16 sm:px-0">
            <h2 id="related-heading" className="text-xl font-bold text-gray-900">
              {t('Prices from other sellers')}
            </h2>

            <div className="text-gray-500">
              <p className="pt-2">
                {t('The prices listed below are as displayed on the respective websites. Additional shipping fees or other charges may apply.')}
              </p>
            </div>
            {
              racket.prices.length === 0 ? (
                <div className="text-gray-500">
                  <p className="pt-6">{t('Price not available')}</p>
                </div>
              ) : (
                <ul role="list" className="divide-y divide-gray-100">
                  {racket.prices
                    .sort((a, b) => a.amountInEUR - b.amountInEUR)
                    .map((price, index) => {
                      const url = new URL(price.link);
                      const domain = url.hostname.replace('www.', '');

                      return (
                        <li key={index} className="flex justify-between gap-x-6 py-5">
                          <div className="flex min-w-0 gap-x-4">
                            <div className="min-w-0 flex-auto">
                              <p className="text-sm font-semibold leading-6 text-gray-900">
                                <a href={price.link} target="_blank" className="text-indigo-600 hover:text-indigo-900" rel="noopener noreferrer">
                                  {domain}
                                </a>
                              </p>
                              <p className="text-sm leading-6 text-gray-900">
                                <FormattedNumber value={price.amount} style="currency" currency={price.currency} /> ({price.amountInEUR} €)
                              </p>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              )
            }
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white transition-transform duration-300 ease-in-out bg-gradient-to-r from-indigo-500 via-indigo-600 to-indigo-700 rounded-md shadow-lg hover:scale-105 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={handleAddPriceClick}
              >
                <FaPlus className="w-5 h-5 mr-2 text-white" /> {/* Icône FaPlus */}
                {t('Add a Price')}
              </button>
            </div>
          </section>

          {/* Afficher la modale pour ajouter un prix */}
          <AddPriceModal
            isOpen={showAddPriceModal}
            onClose={() => setShowAddPriceModal(false)}
            onPriceSubmit={handlePriceSubmit}
            racketUuid={racket.uuid}
          />

          <section aria-labelledby="related-heading" className="mt-10 border-t border-gray-200 px-4 py-16 sm:px-0">
            <h2 id="related-heading" className="text-xl font-bold text-gray-900">
              {t('Equivalent rackets')}
            </h2>

            {racket.relatedRackets.length === 0 && (
              <div className="text-gray-500">
                <p className="pt-6">{t('No equivalent racket')}</p>
              </div>
            )}

            <div className="mt-8 grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              {racket.relatedRackets.map((relatedRacket, index) => (
                <div key={index}>
                  <div className="relative">
                    <div className="relative h-72 w-full overflow-hidden rounded-lg">
                      <Link to={`/badminton/racket/${relatedRacket.uuid}`}>
                        <img
                          src={relatedRacket.images[0]}
                          className="h-full w-full object-contain object-center"
                          alt={relatedRacket.name}
                        />
                      </Link>
                    </div>
                    <div className="relative mt-4">
                      <h3 className="text-sm font-medium text-gray-900">{relatedRacket.name}</h3>
                      <div className="flex flex-1 flex-col justify-end">
                        <p className="text-sm italic text-gray-500">{t('Brand')}: {relatedRacket.brand || t('Information not available')}</p>
                        <p className="text-sm italic text-gray-500">{t('Balance')}: {t(relatedRacket.balance) || t('Information not available')}</p>
                        <p className="text-sm italic text-gray-500">{t('Flexibility')}: {t(relatedRacket.flexibility) || t('Information not available')}</p>
                        <p className="text-sm italic text-gray-500">{t('Weights')}: {relatedRacket.weights ? relatedRacket.weights.join(', ') : t('Information not available')}</p>
                        <IntlProvider locale="fr">
                        <p className="text-base font-medium text-gray-900">
                          {relatedRacket.lowestPrice ? (
                            <FormattedNumber value={relatedRacket.lowestPrice.amount} style="currency" currency={relatedRacket.lowestPrice.currency} />
                          ) : (
                            t('Price not available')
                          )}
                        </p>
                        </IntlProvider>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        {showNotification && (
          <div className="fixed bottom-4 right-4 rounded-md bg-green-50 p-4 shadow-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">{t('Racket added to comparator')}</h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>{t('You can compare this racket with others you have added.')}</p>
                </div>
                <div className="mt-4">
                  <div className="-mx-2 -my-1.5 flex">
                    <button
                      type="button"
                      className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                      onClick={goToComparator}
                    >
                      {t('Go to Comparator')}
                    </button>
                    <button
                      type="button"
                      className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                      onClick={() => setShowNotification(false)}
                    >
                      {t('Dismiss')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {showErrorNotification && (
          <div className="fixed bottom-4 right-4 rounded-md bg-green-50 p-4 shadow-lg">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400" />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-green-800">{t('Thank you for your feedback!')}</h3>
                <div className="mt-2 text-sm text-green-700">
                  <p>{t('Your report has been submitted successfully.')}</p>
                </div>
                <div className="mt-4">
                  <div className="-mx-2 -my-1.5 flex">
                    <button
                      type="button"
                      className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                      onClick={() => setShowErrorNotification(false)}
                    >
                      {t('Dismiss')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <ErrorReportModal
          isOpen={showErrorModal}
          onClose={() => setShowErrorModal(false)}
          onReportSuccess={() => {
            setShowErrorModal(false);
            setShowErrorNotification(true);
          }}
          racketUuid={racket.uuid}
        />
      </main>
    </IntlProvider>
  );
}
